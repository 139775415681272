import React, { useState, useEffect } from "react";
import css from "../css/gamehis.module.css";
import ReactPaginate from "react-paginate";
import axios from "axios";
import Header from '../Components/Header';
import '../Components/Component-css/Common.css';


const Transactionhistory = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  //eslint-disable-next-line
  const [user, setUser] = useState()
  //use for pagination..
    let limit = 100;
    const [pageNumber, setPageNumber] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);

  const role = async () => {
    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    await axios.get(baseUrl+`me`, { headers })
      .then((res) => {
        setUser(res.data)
        
        //Allgames(res.data._id)
        Allgames(res.data._id,pageNumber,limit)
        // window.location.reload()

      })

  }

 const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
    // scroll to the top
    //window.scrollTo(0, 0)
  };
  
const dateFormat=(e)=>{
      
  const date = new Date(e); 
const newDate = date.toLocaleString('default', { month: 'long',day:'numeric',hour:'numeric',hour12:true,minute:'numeric' });
return newDate;
  }

  const [cardData, setGame] = useState()

  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    await axios.get(baseUrl+`temp/deposite/${id}?page=${pageNumber}&_limit=${limit}`, { headers })
      .then((res) => {
        setGame(res.data.ress)
        setNumberOfPages(res.data.totalPages);

      })

  }


  useEffect(() => {
    role()
    //eslint-disable-next-line
  }, [pageNumber,limit])


  return (
    <div>

      <Header user={user} />
      <br></br><br></br><br></br><br></br><br></br>
      <div className="leftContainer transcation_header" style={{minHeight:'100%', height:'100%', background: 'var(--bg-blue-light)!important', paddingBottom: '10rem'}}>
        {/* pagination */}
    
        

        {/* game-cards */}
        { cardData && cardData.map((card) => {
        
          var id          = card._id.toString(), ctr = 0;
          var timestamp   = id.slice(ctr, (ctr+=8));
          var machineID   = id.slice(ctr, (ctr+=6));
          var processID   = id.slice(ctr, (ctr+=4));
          var counter     = id.slice(ctr, (ctr+=6));
          //console.log("id:", id);
          //console.log("timestamp:", parseInt(timestamp, 16));
          //console.log("machineID:", parseInt(machineID, 16));
          //console.log("processID:", parseInt(processID, 16));
          var counterid =parseInt(timestamp, 16);  
        
        if((card.Req_type==='deposit' ||  card.Req_type==='bonus') && card.status!='FAILED')
          {
            var titleMsg = 'Cash added';
            if(card.status==='Pending' && card.Req_type==="deposit"){
              var signIcon = <div className="" style={{color: "#9c0000", fontSize: "1.2rem", paddingRight: '0.5rem'}}><i class="fa-solid fa-xmark"></i></div>;
            }else{
              var signIcon = <div className="" style={{color: "#03b300", fontSize: "1.2rem", paddingRight: '0.5rem'}}><i class="fa-solid fa-check"></i></div>;
            }
          }
          else if(card.Req_type==="withdraw" && card.status!='FAILED')
          {
            var titleMsg = 'Witdraw using '+ card.Withdraw_type;
            var signIcon = <div className="" style={{color: "#9c0000", fontSize: "1.2rem", paddingRight: '0.5rem'}}><i class="fa-solid fa-xmark"></i></div>;
          }
          else if(card.Req_type==='penalty' && card.status!='FAILED')
          {
            var titleMsg = 'Penalty';
            var signIcon = <div className="" style={{color: "#9c0000", fontSize: "1.2rem", paddingRight: '0.5rem'}}><i class="fa-solid fa-xmark"></i></div>;
          }
          else if(card.status==='Pending' || card.status==='FAILED' )
          {
            var titleMsg = 'FAILED';
            var signIcon = <div className="" style={{color: "#9c0000", fontSize: "1.2rem", paddingRight: '0.5rem'}}><i class="fa-solid fa-xmark"></i></div>;
          }
          else{
            var titleMsg = '';
            var signIcon = <div className="text-success"></div>;
          }
          
          return (

          
            <div>
              
              {/* <div
                className={`w-100 mt-3 py-3 d-flex align-items-center ${css.list_item}`}
              > */}

              <div className="transaction_container">
                {/* DATE AND TIME */}
                
                {/*
                <div className={`${css.center_xy} ${css.list_date} mx-2`}>
                  <div style={{fontFamily:'Poppins'}}
                  >{dateFormat(card.createdAt).split(',')[0]}</div>
                  <small>{dateFormat(card.createdAt).split(',')[1]}</small>
                </div>
*/}

                {/* <div className={`${css.list_divider_y}`} /> */}

                <div className="transaction_subcontainer">
                    <div className={`mx-3 d-flex ${css.list_body}`}>
                      <div className="d-flex align-items-center">
                        <picture className="mr-2">
                          <img
                            height="42px"
                            width="42px"
                            src={process.env.PUBLIC_URL + '/Images/LandingPage_img/Header_profile.png'}
                            alt=""
                            style={{ borderRadius: "5px" }}
                          />
                        </picture>
                      </div>

                      <div className="d-flex flex-column" 
                      style={{color: '#000000', fontFamily: 'Poppins'}}>
                        <div style={{fontFamily:'Poppins', fontSize: '1rem'}}>
                          {titleMsg}
                          <h6 
                          style={{fontFamily:'Poppins', fontWeight:'300', letterSpacing: '1px', fontSize: '0.75rem'}}>
                            OrderID: {counterid}</h6>
                        </div>


                        <div className={`${css.games_section_headline}`} style={{color: '#000000'}}>
                          Status: {(card.status==='none' || (card.status==='Pending' && card.Req_type==="withdraw" )) ? 'Processing': (card.status==='Pending' && card.Req_type==="deposit" )? 'Cancelled':card.status}
                          <br></br>
                          
                          {(card.txn_msg)? card.txn_msg:''}
                        </div>
                      </div>
                    </div>

                    <div className="right-0 d-flex align-items-end pr-3 flex-column">
                        {/* <button className={`btn btn-sm ${css.btn_success} ${css.status_badge}`}>
                          PAID
                        </button> */}
                        <div className="d-flex float-right font-8">
                        {signIcon}
                          <picture className="ml-1 mb-1">
                            <img
                            alt="img"
                              height="21px"
                              width="21px"
                              src={process.env.PUBLIC_URL + '/Images/LandingPage_img/global-rupeeIcon.png'}
                              className="snip-img"
                            />
                          </picture>
                          <span className="pl-1" style={{fontSize: '1.2rem', color: '#000000', paddingLeft: '0.5rem'}}>{card.amount}</span>
                        </div>
                        {card.closing_balance&&<div
                          className={`${css.games_section_headline}`}
                          style={{ fontSize: "0.6em",whiteSpace:'nowrap',color: '#000000' }}
                        >
                          closing balance :{card.closing_balance}
                        </div>}
                        <div
                          className="games-section-headline"
                          style={{ fontSize: "0.6em" }}
                        >
                        
                        </div>
                    </div>
                </div>

              </div>
            </div>

          );
        })
        }
        {cardData && cardData.length === 0 &&
          <div className="text-center">
            <img
              src={process.env.PUBLIC_URL + '/Images/notransactionhistory.png'}
              alt="no data"
              width={300}
              height={300}
              className="img-fluid "
              style={{ marginTop: "25%" }}
            />
            <div className="mt-2">
              <h3 className="font-weight-bold">No transaction History</h3>
              <p className="text-muted"> You have not made any transaction  yet.</p>
             </div>
          </div>
        }
      </div>
    </div>
  );
};

export default Transactionhistory;
